<template>
  <div class="input-address">
    <b-form-input :ref="REF_ADDR_INPUT" v-model="addressInput" required />
    <b-list-group
      v-show="results.length > 0"
      class="shadow position-absolute dd-results"
    >
      <b-list-group-item
        v-for="result in sortedResults"
        :key="result.id"
        :style="{ zIndex: 1 }"
        @click="selectItem(result, $event)"
        button
        :disabled="!result.id"
        >{{ result.address.freeformAddress }}</b-list-group-item
      >
    </b-list-group>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'input-address',
  model: {
    prop: 'addressLocation',
    event: 'select'
  },
  props: {
    addressLocation: Object,
    format: { type: String, default: '{freeformAddress}' }
  },
  data() {
    return {
      REF_ADDR_INPUT: 'addr-input',
      results: [],
      addressInput: this.formatAddress(this.addressLocation.address),
      isSelected:
        !!this.addressLocation.address &&
        !!this.addressLocation.position.lat &&
        !!this.addressLocation.position.lon,
      lockSearch: false
    }
  },
  computed: {
    selected: {
      get() {
        return this.isSelected
      },
      set(value) {
        this.isSelected = value
        const msg = value
          ? ''
          : 'Veuillez choisir une adresse dans la liste proposée'

        this.$refs[this.REF_ADDR_INPUT].setCustomValidity(msg)
      }
    },
    sortedResults() {
      return [...this.results].sort((r1, r2) => {
        return Math.sign(r2.score - r1.score)
      })
    }
  },
  mounted() {
    this.selected = this.selected // Trick to call setCustomValidity
  },
  watch: {
    addressInput: function() {
      if (!this.lockSearch) {
        this.getAddresses()
        this.selected = false
      }
    },
    addressLocation: {
      handler: function(value) {
        this.selected = true
        this.updateAddressInput(this.formatAddress(value.address))
      },
      deep: true
    }
  },
  methods: {
    updateAddressInput(value) {
      this.lockSearch = true
      this.addressInput = value
      this.$nextTick(() => (this.lockSearch = false))
    },
    getAddresses: _.debounce(async function() {
      let results = []

      if (this.addressInput) {
        try {
          const url = new URL('https://atlas.microsoft.com/search/address/json')
          url.searchParams.append('api-version', '1.0')
          url.searchParams.append(
            'subscription-key',
            process.env.VUE_APP_AZURE_MAPS_API_Key
          )
          url.searchParams.append('countrySet', 'FR')
          url.searchParams.append('typeahead', 'true')
          url.searchParams.append('query', this.addressInput)

          const response = await fetch(url)

          if (response.ok) {
            const data = await response.json()

            results =
              data.summary.numResults > 0
                ? data.results
                : [{ address: { freeformAddress: 'Pas de résultat.' } }]
          } else {
            console.error(
              `Error while fetching addresses: ${response.status} ${response.statusText}`
            )
          }
        } catch (err) {
          console.error('Uncaught error while fetching addresses: ', err)
        }
      }

      this.results = results
    }, 200),
    selectItem(result, event) {
      this.selected = true
      this.$emit('select', result)
      this.updateAddressInput(this.formatAddress(result.address))
      this.results = []
      event.preventDefault()
    },
    formatAddress(address) {
      let result = undefined

      if (typeof address == 'string') {
        result = address
      } else if (typeof address == 'object' && this.format) {
        result = this.format
          .split(/(\{[^{}]+\})/)
          .map(e => {
            if (e.startsWith('{') && e.endsWith('}')) {
              return address[e.replace(/[{}]/g, '')]
            } else {
              return e
            }
          })
          .join('')
      }

      return result
    }
  }
}
</script>

<style lang="scss">
.input-address {
  .dd-results {
    z-index: 999;
    max-height: 300px;
    overflow-y: auto;
    width: 100%;
  }
}
</style>
